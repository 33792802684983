/* Footer.css */

.footer {
    position: relative;
    bottom: 0;
    left: 0;
    background-color: #c0cb6b;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    z-index: 1000;
  }
  
  .footer .logo img {
    height: 40px;
  }
  
  .footer .menu a {
    color: #fff;
    text-decoration: none;
    margin-left: 10px;
  }
  
  /* Estilos para hacer el footer responsive */
  @media (max-width: 768px) {
    .footer {
      flex-direction: column;
      align-items: center;
    }
  
    .footer .menu {
      margin-top: 10px;
    }
  
    .footer .menu a {
      margin-left: 0;
      margin-top: 5px;
    }
  }
  