/* Header.css */

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #c0cb6b;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  z-index: 1000;
}

.logo img {
  height: 80px;
  margin-left: 50px;
}

.menu a {
  padding: 10px 20px; /* Ajusta el padding para hacer los botones más grandes */
  transition: transform 0.3s ease; /* Cambia la propiedad a 'transform' */
}

/* Efecto de cambio de tamaño al pasar el ratón sobre los botones */
.menu a:hover {
  transform: scale(1.1); /* Ajusta el factor de escala según tus preferencias */
}

.menu {
  margin-right: 100px; /* Ajusta el margen a la derecha para evitar que se salgan de la pantalla */
}

.menu img {
  height: 80px; /* Ajusta la altura de las imágenes según tus necesidades */
  animation: pulse 2s infinite; /* Usa la animación 'pulse' definida anteriormente */
  transition: box-shadow 0.3s ease; /* Transición suave para el box-shadow */
}

.menu img:hover {
  transform: scale(1.1); /* Ajusta el factor de escala según tus preferencias */
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.7); /* Efecto de brillo */
  background-color: rgb(155, 19, 44);
}

/* Estilos para hacer el header responsive */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: center;
  }

  .menu {
    margin-top: 10px;
    align-items: center;
  }

  .menu a {
    margin-left: 0;
    margin-top: 5px;
  }

  .menu img {
    margin-left: 0;
    margin-top: 5px;
    height: 40px;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
