.first-section {
  padding: 50px 20px;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.text {
  width: 50%;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50px; /* Bordes circulares */
  padding: 20px; /* Espaciado interno */
}

.text h2 {
  font-size: 32px;
  margin-bottom: 15px;
  color: #783bab;
  -webkit-text-stroke: 1px black; /* Borde negro alrededor de las letras en Webkit (Chrome, Safari, etc.) */
  text-stroke: 1px black; /* Borde negro alrededor de las letras en navegadores compatibles */
  display: inline-block; /* Evita que el borde se extienda más allá del área del texto */
  padding: 5px; /* Espaciado para que el borde no esté pegado a las letras */
}

.text p {
  font-size: 20px;
  color: #000000;
  text-align: start;
}

.image {
  width: 30%;
  text-align: right;
}

.image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px; /* Ajusta según tus preferencias */
}

.image-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%; /* Establece un cuadrado (altura igual al ancho) */
  border-radius: 50%; /* Hace que el contenedor sea circular */
  overflow: hidden; /* Oculta el contenido que se sale del círculo */
  background: url("../images/catGraveyard.gif") center/cover no-repeat; /* Ajusta la ruta según la ubicación de tu otra imagen de fondo */
  border: 20px solid black; /* Ajusta el ancho del borde */
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ajusta la propiedad de ajuste del objeto para la imagen */
}

/* Estilos para hacer responsive las imágenes en columna */
@media (max-width: 768px) {
  .content {
    flex-direction: column; /* Cambia a la dirección de columna en el modo responsivo */
    margin-top: 50px !important;
  }
  .text {
    margin-top: 50px !important;
  }
  .image img {
    margin-right: 20px;
  }
}
