.about-section {
  position: relative;
  overflow: hidden;
  /* height: 100vh; */
  background: #c0cb6b;
  background-size: cover;
  transition: background-position-y 0.3s ease;
}

.parallax-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #c0cb6b;
  background-size: cover;
  transform: translateZ(0);
  z-index: -1;
}

.about-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 20px;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-image,
.right-image {
  width: 150px;
  height: auto;
  position: relative;
  z-index: 2;
  transition: transform 0.3s ease; /* Añade una transición para un efecto suave */
}

.left-image {
  left: 0;
}

.right-image {
  right: 0;
}

.left-image img,
.right-image img {
  width: 100%;
  height: auto;
  display: block; /* Asegura que la imagen sea un elemento de bloque */
}

.about-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 20px;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.about-image-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.about-image {
  position: relative;
  flex: 1 1 calc(33.3333% - 20px); /* Cambié el tamaño para ajustar al espacio y añadí un margen */
  overflow: hidden;
  border-radius: 10px;
  width: 500px;
  margin-bottom: 20px !important; /* Añadí un margen inferior */
}

.about-image img {
  width: 200px;
  height: 150px;
}

.about-caption {
  position: relative;
  bottom: 0;
  left: 0;
  padding: 10px;
}

.about-caption h3 {
  margin-bottom: 20px;
  font-size: 32px;
  color: #ffffff;
  -webkit-text-stroke: 1px black; /* Borde negro alrededor de las letras en Webkit (Chrome, Safari, etc.) */
  text-stroke: 1px black; /* Borde negro alrededor de las letras en navegadores compatibles */
  display: inline-block; /* Evita que el borde se extienda más allá del área del texto */
  padding: 5px; /* Espaciado para que el borde no esté pegado a las letras */
}

.about-caption p {
  margin: 0;
  font-size: 20px;
  color: #ffffff;
  -webkit-text-stroke: 1px black; /* Borde negro alrededor de las letras en Webkit (Chrome, Safari, etc.) */
  text-stroke: 1px black; /* Borde negro alrededor de las letras en navegadores compatibles */
  display: inline-block; /* Evita que el borde se extienda más allá del área del texto */
  padding: 5px; /* Espaciado para que el borde no esté pegado a las letras */
}

.about-border-top,
.about-border-bottom {
  height: 5px;
  background-color: black;
  position: absolute;
  left: 0;
  right: 0;
}

.about-border-top {
  top: 0;
}

.about-border-bottom {
  bottom: 0;
}

/* Estilos para hacer responsive las imágenes en columna */
@media (max-width: 768px) {
  .about-image {
    flex: 1 1 100%;
    margin-left: -75px;
  }
  .left-image,
  .right-image {
    display: none;
  }
}
